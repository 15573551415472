.btn-image {
    width: 100%;
}

.btn-image-wrapper {
    width: 63%;
    border-radius: 50%;
    background: white;
    padding: 3px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);   
}

.buttons {
    display: flex;
    padding-top: 5px;
    justify-content: space-between;
    max-width: 1250px;
    margin: auto;
    flex-wrap: wrap;
}

.button-title {
    overflow: hidden;
    font-size: 13px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 90%;
    margin: auto;
}

.button-link {
    width: 25%;
    text-decoration: none;
    text-align: center;
    color: black;
    font-size: 16px;
    padding-bottom: 5px;
}

@media only screen and (min-width: 1020px) {
    .buttons {
        padding-top: 12px;
    }

    .button-title {
        font-size: 16px;
    }
    .button-link {
        width: 10%
    }

    .button-link-active .button-title {
        transform: scale(1.2);
    }

    .button-link:hover .button-title {
        font-weight: bold;
        transition: transform 0.3s;
        transform: scale(1.2);
    }
    
    .btn-image-wrapper {
        width: 75%;
    }
}

.button-link-active .btn-image,
.button-link:hover .btn-image {
    transition: transform 0.3s;
    transform: scale(1.3);
}

.button-link-active .btn-image-wrapper,
.button-link:hover .btn-image-wrapper {
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.42);
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.42);
}

.button-link-active {
    font-weight: bold;
}