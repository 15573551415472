.tiles {
    padding: 5px 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    justify-items: center;
}

.tiles-wrapper {        
    -webkit-box-shadow: 0px -4px 7px -2px rgba(0,0,0,0.1);
    box-shadow: 0px -4px 7px -2px rgba(0,0,0,0.1);
}

@media only screen and (min-width: 600px) {
    .tiles {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 21px;
    }
}

@media only screen and (min-width: 1024px) {
    .tiles {
        padding: 13px 40px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 31px;
        max-width: 1250px;
        margin: 0 auto;
    }

    .tiles-wrapper {               
        margin-top: 5px;         
    }
} 

.filters {
    display: flex;
    justify-content: center;
    height: 50px;
    padding-top: 8px;
}

#filter-billa {
    background-image: url('../images/billapassive.png');    
}

#filter-billa.selected {
    background-image: url('../images/billaactive.png'); 
}
 
#filter-fantastico {
    background-image: url('../images/ffpassive.png'); 
}

#filter-fantastico.selected {
    background-image: url('../images/ffactive.png'); 
}

#filter-lidl {
    background-image: url('../images/lidlpassive.png'); 
}

#filter-lidl.selected {
    background-image: url('../images/lidlactive.png'); 
}

#filter-kaufland {
    background-image: url('../images/kauflandpassive.png'); 
}

#filter-kaufland.selected {
    background-image: url('../images/kauflandactive.png'); 
}

#filter-t-market {
    background-image: url('../images/tmarketpassive.png'); 
}

#filter-t-market.selected {
    background-image: url('../images/tmarketactive.png'); 
}

#filter-billa, 
#filter-fantastico,
#filter-lidl,
#filter-kaufland,
#filter-t-market {
    width: 45px;
    background-size: 45px;
    background-repeat: no-repeat;
    background-position: top;
    cursor: pointer;
    padding: 0 8px;
}

@media only screen and (min-width: 1020px) {
    #filter-billa, 
    #filter-fantastico,
    #filter-lidl,
    #filter-kaufland,
    #filter-t-market {
        width: 50px;
        background-size: 50px;
        padding: 0 10px;
    }

    #filter-fantastico:hover {
        background-image: url('../images/ffactive.png'); 
    }

    #filter-t-market:hover {
        background-image: url('../images/tmarketactive.png'); 
    }

    #filter-kaufland:hover {
        background-image: url('../images/kauflandactive.png'); 
    }

    #filter-lidl:hover {
        background-image: url('../images/lidlactive.png'); 
    }

    #filter-billa:hover {
        background-image: url('../images/billaactive.png'); 
    }
}

.no-products {
    max-width: 1250px;
    margin: auto;
    padding: 30px 30px;
    font-size: 18px;
}