.search-suggestions {
    width: 96%;
    overflow-x: hidden !important;
    background: white;
    list-style: none;
    position: absolute;
    z-index: 100;
    max-height: 400px;
    padding: 0;
    margin-top: -17px;
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.22);
    font-size: 17px;
}

.search-suggestions a {
    text-decoration: none !important;
    color: black;
}


@media only screen and (min-width: 765px) {
    .search-suggestions {
        width: 98%;
    }
}

@media only screen and (min-width: 1020px) {
    .search-suggestions {
        width: 100%;
    }
}

.suggestion {
    padding: 10px 16px;
    cursor: pointer;
    border-bottom: 1px solid rgb(233, 235, 238);
}

.suggestion:hover {
    background: rgb(233, 235, 238);
}

.view-all {
    padding: 5px 16px;
    text-decoration: underline;
}