.category-tiles-wrapper {
    padding: 10px 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 0 auto;
}

@media only screen and (min-width: 765px) {
    .category-tiles-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 21px;
    }
}

@media only screen and (min-width: 1020px) {
    .category-tiles-wrapper {
        max-width: 1250px;
        padding: 20px 0;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 45px;
    }
}

.reduced-opacity {
    opacity: 0.5;
}

.hidden {
    display: none !important;
}