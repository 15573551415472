@font-face {
    font-family: 'IBMPlex';
    src: url('./fonts/IBMPlexSans-Regular.ttf');
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'IBMPlex';
    background: rgb(255, 239, 219);
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}