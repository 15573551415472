.tile {
    position: relative;
    max-width: 179px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
    border-radius: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: white;
}

@media only screen and (min-width: 600px) {
    .tile {
		max-width: 240px;
	}
}

.tile:hover {
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.22); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.22);
}

.name {
    display: flex; 
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 3px;
}

.name-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.badge {
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    color: white;
    top: 15px;
    left: -7px;
    background: rgb(255, 66, 0);;
    padding: 5px 18px;
    border-top-left-radius: 4px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.badge:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid rgb(255, 66, 0);;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.quantity {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 10px;
    color: grey;
}

.validUntil {
    font-size: 13px;
    text-align: left;
    padding: 3px 0 2px 10px;
    color: grey;
}

.price {
    font-size: 28px;
    font-weight: bold;
}

.price-currency {
    font-size: 13px;
}

.price-smalls {
    font-size: 15px;
}

.old-price-currency {
    font-size: 9px;
}

.old-price-smalls {
    font-size: 10px;
}

.old-price {
    text-decoration: line-through;
    font-size: 19px;
    line-height: 24px;
    color: red;
}

.product-img {
    margin-bottom: 10px;
    margin-top: 10px;
}

.product-img img {
    max-height: 130px;
    max-width: 130px;
    width: auto;
}

@media only screen and (min-width: 1020px) {
    .product-img img {
        max-height: 160px;
        max-width: 160px;
    }    
}

.price-info-wrapper {
    display: flex;
    margin: 10px 20px;
    justify-content: space-around;
}

.logo img {
    width: 31px;
    height: 86px;
    top: 0;
    position: absolute;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    right: 12px;
}

.info-wrapper {
    margin-top: auto;
}

.productText {
    width: 1px;
    font-size: 0px;
    height: 1px;
}