.header {
    display: flex;
    max-width: 1250px;
    margin: 0 auto;
    justify-content: space-between;
}

.heading-title {
    font-size: 0;
    width: 1px;
    height: 1px;
}

.header-wrapper {
    background-color: #232f3e;
}

.logo-title {
    position: relative;
}

.title-info {
    margin: auto;
    margin-left: 20px;
    background-image: url('../images/titleinfo.png');
    background-size: 209px;
    background-repeat: no-repeat;
    background-position: left; 
    margin-top: -5px;
}

.title-info-text {
    color: rgb(255, 239, 219);
    letter-spacing: 1px;  
    vertical-align: top;
    margin-left: 27px;
    font-size: 12px;
}

.logo-header { 
    display: block;
    position: absolute;
    top: 10px;
    height: 105%;
    width: 115px;
    background-image: url('../images/titlemobile.png');
    background-size: 112px;
    background-repeat: no-repeat;
    background-position: right;
}

@media only screen and (min-width: 765px) {
    .logo-header {
        top: 15px;
        height: 105%;
        width: 250px;
        background-image: url('../images/title.png');
        background-size: 253px;
    }

    .title-info {     
        margin: auto;
        margin-top: -2px;   
        max-width: 1144px;
        background-size: 252px;        
    }

    .title-info-text {
        margin-left: 41px;
        font-size: 14px;
    }
}
