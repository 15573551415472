.footer {
    display: flex;
    background-color: #232f3e;
    color: rgb(255, 239, 219);
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 15px;
    flex-direction: column;
}

.footer-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3px;
}

.footer p {
    margin: 0;
}

.footer a {
    color: rgb(255, 239, 219);
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
}