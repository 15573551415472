.form-wrapper {
    flex-basis: 65%;
    position: relative;
    padding-right: 10px;
}

.search-form {
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin: 15px 0;
    border-radius: 5px;
}

.search-form:focus-within {
    box-shadow: 0 0 0 2px rgb(245, 244, 242), 0 0 0 3px rgb(235 255 253 / 50%);
}

.search-input {
    outline: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 16px;
    font-size: 13px;
    width: 90%;
    border: none;
}

.search-button {
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 17%;
    font-size: 29px;
    background-color: white;
    cursor: pointer;
    color: #232f3e;
}

.search-button:hover {
    background: rgb(233, 235, 238);
}

.close-button {
    background: white;
    padding: 0 5px;
    position: relative;
    width: 10%;
    cursor: pointer;
}

.close-button::before {
    content: 'x';
    color: grey;
    font-weight: 300;
    font-size: 25px;
    position: absolute;
    top: 5px;
    left: 8px;
  }

.close-button:hover {
    background: rgb(233, 235, 238);
}

.search-icon {
    font-size: 23px;
}
 
@media only screen and (min-width: 765px) {
    .close-button {
        width: 5%;
    }
}

@media only screen and (min-width: 1020px) {
    .search-icon {
        font-size: unset;
    }

    .form-wrapper {
        padding-right: 0;
        flex-basis: 75%;
    }

    .search-input {
        font-size: 20px;
    }

    .search-button {
        width: 10%;
    }

    .close-button {
        width: 2%;
    }
}